import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import { OutboundLink } from "gatsby-plugin-google-gtag"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="byjos.dev" location={location}>
    <Seo title="About me" />
    <div>
      <p>
       I am an experienced Head of Cloud Infrastructure and DevOps with a proven track record in managing pipelines, ensuring safe deployments, and consistent builds. 
        I possess strong expertise in cloud architecture and integration, specializing in developing scalable solutions using serverless technologies on Google Cloud. 
        Skilled in client collaboration and project rollouts. I am certified{" "}
        <OutboundLink href="https://www.credential.net/93358416-e42e-4974-9c8e-5a91c5fa033d">
          GCP Developer
        </OutboundLink>{" "}
        and{" "}
        <OutboundLink href="https://www.credential.net/b7e20cd0-4403-4768-bec5-e99b0d5e51aa">
          Architect
        </OutboundLink>
        .
      </p>
      <p>
        I love serverless solutions which make it easy to focus on a goal and
        not worry about all complicated stuff (have you tried k8s federation?).
        Additionally I know something about Android&Flutter apps as I was also
        responsible for the whole launch chain from the scratch.
      </p>
      <p>
        Previously, when at Google, I was responsible for launching Google
        Assistant in Poland from the product quality/readiness side, technical
        partnerships and partialy marketing campaigns.
      </p>

      <p> <a href={"/cv/cv_public_lukasz_byjos.pdf"} title="Lukasz Byjoś CV">There</a> you can find my current CV.</p>
    </div>
    <div className="bio-footer">
      <Bio />
    </div>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
